import React from "react"
import PropTypes from "prop-types"
import Avatar from "../../../../../UI/Avatar"
import styled from "styled-components"
import { Lock, Unlock } from "grommet-icons"
import Button from "../../../../../UI/Button"
import { colors } from "../../../../../../theme"
import EditableText from '../../../../../UI/EditableText'

const Wrapper = styled.div`
  background-color: #fff;
  padding: 12px;
  border-radius: 9px;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProjectHero = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  > * { margin-bottom: 10px; }
  > :last-child { margin-bottom: 0; }
  img {
    max-width: 250px;
    max-height: 300px;
  }
  > div {
    display: flex;
    align-items: center;
  }
`

const ProjectTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
`

const Author = styled.div`
  width: 100%;
  justify-content: center;
  top: 20px;
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 10px;
  }
  margin-bottom: 8px;
`

const Privacy = styled.div`
  top: 10px;
  right: 10px;
`

const SubProjects = styled.div`
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  > :first-child {
    font-weight: bold;
    color: ${colors.indigo};
    margin-bottom: 6px;
    text-align: center;
  }
  > :nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const SubProject = styled.div`
  position: relative;
  color: #000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * { margin-bottom: 10px; }
  > :last-child { margin-bottom: 0; }
  img {
    max-width: 250px;
    max-height: 300px;
  }
`

const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  > * {
    margin-right: 8px;
  }
`

const Project = props => {
  const { updateProject, updateTopicWithId } = props

  return (
    <Wrapper>
      <ProjectHero>
        <div />
        <ProjectTitle><EditableText onSave={name => updateProject({ name })}>{props.name}</EditableText></ProjectTitle>
        <div><EditableText onSave={description => updateProject({ description })}>{props.description}</EditableText></div>
        <Author>
          <Avatar user={props.user} />
          <div>{props.user.username}</div>
        </Author>
        <img src={props.backgroundUrl} alt="" />
        <Privacy onClick={() => updateProject({ isPrivate: !props.isPrivate })}>
          {props.isPrivate ? (
            <><Lock size="20px" color="#000" /><span style={{ marginLeft: '10px' }}>Only collaborators can see it</span></>
          ) : (
            <><Unlock size="20px" color="#000" /><span style={{ marginLeft: '10px' }}>Everyone can see it</span></>
          )}
        </Privacy>
      </ProjectHero>
      <SubProjects>
        <div>Topics</div>
        <div>
          {props.projects.map(p => (
            <SubProject key={p.id} {...p}>
              <div><EditableText onSave={name => updateTopicWithId(p.id, { name })}>{p.name}</EditableText></div>
              <div><EditableText onSave={description => updateTopicWithId(p.id, { description })}>{p.description}</EditableText></div>
              <img src={p.backgroundUrl} alt="" />
            </SubProject>
          ))}
        </div>
      </SubProjects>
      <Actions>
        {props.publish && (
          <Button onClick={props.publish} bgColor={colors.green}>
            Publish
          </Button>
        )}
        {props.reject && (
          <Button onClick={props.reject} bgColor={colors.red}>
            Reject
          </Button>
        )}
      </Actions>
    </Wrapper>
  )
}

Project.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  projects: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  isPrivate: PropTypes.bool,
  user: PropTypes.shape({
    _id: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  collaborators: PropTypes.shape({
    _id: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  publish: PropTypes.func,
  reject: PropTypes.func,
}

Project.defaultProps = {
  user: {},
}

export default Project
